//react component called StartClockingModal

import React from "react";
import { Button, Col, Grid, Modal, MultiSelect, Select, Space, Text, Textarea } from "@mantine/core";
import { ICustomer } from "../../models/customer";
import { useAddClockingMutation } from "../../redux/apis/clockingApi";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  customer: ICustomer
  onClose: () => void,
  userId: string,
  userName: string,
  submitEnabled?: boolean
}

export const StartClockingModal: React.FC<Props> = ({
  customer,
  onClose,
  userId,
  userName,
  submitEnabled = true
}: Props) => {
  const [createClocking, { isLoading }] = useAddClockingMutation();
  const { settings } = useAppSelector(s => s.settings);
  const [submitting, isSubmitting] = React.useState(false);
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);

  const form = useForm({
    initialValues: {
      dateStart: new Date(),
      dateEnd: null,
      userId: userId,
      userName: userName,
      customerId: customer.id,
      service: '',
      serviceDescription: ''
    },
  });

  const handleCreateClocking = async (values) => {
    if (!selectedServices || selectedServices.length === 0) {
      showNotification({
        title: "Errore",
        message: "Si prega di selezionare un servizio",
        color: "red",
      });
      return;
    }
    isSubmitting(true);
    values.userId = userId;
    values.service = selectedServices.join(',');
    await createClocking(values).unwrap()
      .then(async () => {
        showNotification({
          title: "Success",
          message: "Timbratura creata con successo",
          color: "teal",
        });
        onClose();
      })
      .catch((err) => {
        if (err.originalStatus === 400)
          showNotification({
            title: "Errore",
            message: "Non puoi avviare più di un'attività contemporaneamente",
            color: "red",
          });
        else

          showNotification({
            title: "Error",
            message: err.error,
            color: "red",
          });
      });
    isSubmitting(false);
  };

  return (
    <Modal
      title="Inizia lavorazione"
      onClose={onClose}
      size="md"
      fullScreen
      overlayOpacity={0.7}
      transition="slide-up"
      opened={true}>
      <form onSubmit={form.onSubmit(handleCreateClocking)} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80vh'
      }}>
        <div>
          <Text size="xl" mt="lg" mb="lg">
            Cliente: <b>{customer.description}</b>
          </Text>
          <Text>
            <div>{customer.address}, {customer.city}, {customer.province} {customer.zipCode}</div>
          </Text>
          <Space h="md" />
          <Grid>
            <Col xs={12}>
              <MultiSelect
                label="Servizi"
                data={settings?.services?.map(x => x.label) ?? []}
                placeholder="Seleziona categorie"
                searchable
                creatable
                value={selectedServices}
                onChange={setSelectedServices}
              />
            </Col>
            {form.getInputProps("service").value === 'Altro' &&
              <Col xs={12}>
                <Textarea label="Descrizione" {...form.getInputProps("serviceDescription")} />
              </Col>}
          </Grid>
        </div>
        <div>
          <Button
            style={{ marginTop: 16 }}
            type="submit"
            color="teal"
            variant="filled"
            fullWidth
            loading={submitting}
            disabled={isLoading || !submitEnabled}
          >
            Avvia
          </Button>
        </div>
      </form>
    </Modal>
  );
}

