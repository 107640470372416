import { createApi } from "@reduxjs/toolkit/query/react";
import { IAgent } from "../../models/agent";
import { IListResponse } from "../../models/api";
import { baseQueryWithReauth } from "./authApi";

export interface IGetAgentRequest {
  search: string;
  orderBy: string;
  descending: boolean;
  limit: number;
  skip: number;
  columnsFilter: string[];
  columnsFiltersValues: string[];
}

export const agentApi = createApi({
  reducerPath: "agentApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAgents: builder.query<IListResponse<IAgent>, IGetAgentRequest>({
      query: (args) => {
        const columnFilters = args.columnsFilter.map(
            (x) => `&columnsToFilter=${x}`
        );
        const columnFiltersValues = args.columnsFiltersValues.map(
            (x) => `&columnsFilterValues=${x}`
        );

        return {
          url: `/api/agents?search=${args.search}&orderBy=${
              args.orderBy
          }&orderDesc=${args.descending}&limit=${args.limit}&skip=${
              args.skip
          }${columnFilters.join("&")}${columnFiltersValues.join("&")}`,
        };
      },
    }),
    // Creare un nuovo agente
    createAgent: builder.mutation<IAgent, Partial<IAgent>>({
      query: (newAgent) => ({
        url: "/api/agents",
        method: "POST",
        body: newAgent,
      }),
    }),
    // Modificare un agente esistente
    updateAgent: builder.mutation<IAgent, Partial<IAgent>>({
      query: (updates) => ({
        url: `/api/agents`,
        method: "PUT",
        body: updates, // L'ID è incluso nel payload, non nell'URL
      }),
    }),
    // Cancellare un agente
    deleteAgent: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/agents/${id}`,
        method: "DELETE",
      }),
    }),
    // Ottenere un singolo agente tramite ID
    getAgent: builder.query<IAgent, string>({
      query: (id) => ({
        url: `/api/agents/${id}`,
      }),
    }),
  }),
});

// Export hooks per l'utilizzo nei componenti
export const {
  useGetAgentsQuery,
  useCreateAgentMutation,
  useUpdateAgentMutation, // Hook per modificare un agente
  useDeleteAgentMutation, // Hook per cancellare un agente
  useGetAgentQuery, // Hook per ottenere un agente tramite ID
} = agentApi;
