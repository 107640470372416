import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import React, {useEffect} from "react";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import Layout from "./pages/Layout";
import Settings from "./pages/Settings";
import Inspections from "./pages/Inspections";
import InspectionDetail from "./pages/Inspections/InspectionDetail";
import InspectionCreate from "./pages/Inspections/InspectionCreate";
import { Route, Switch } from "wouter";
import ProposteTecniche from "./pages/ProposteTecniche";
import ProposteTecnicheDetail from "./pages/ProposteTecnicheDetail";
import ProposteTecnicheCreate from "./pages/ProposteTecnicheCreate";
import InspectionMovements from "./pages/Inspections/InspectionMovements";
import Warehouse from "./pages/Warehouse";
import Upload from "./pages/Warehouse/Upload";
import Download from "./pages/Warehouse/Download";
import WarehouseItemDetail from "./pages/Warehouse/WarehouseItemDetail";
import FirstContact from "./pages/FirstContact/FirstContacts";
import FirstContactCreate from "./pages/FirstContact/FirstContactCreate";
import FirstContactDetail from "./pages/FirstContact/FirstContactDetail";
import {Vehicles} from "./pages/Vehicles/Vehicles";
import {VehicleDetail} from "./pages/Vehicles/VehicleDetail";
import {VehicleCreate} from "./pages/Vehicles/VehicleCreate";
import {ClockingList} from "./pages/Clocking/List";
import {ClockingCreate} from "./pages/Clocking/Create";
import {ClockingDetail} from "./pages/Clocking/Detail";
import {useAppDispatch} from "./redux/hooks";
import {setSettings} from "./redux/slices/settingsSlice";
import {useGetSettingsQuery} from "./redux/apis/settingsApi";
import CustomersCreate from "./pages/CustomersCreate";
import CustomersDetail from "./pages/CustomersDetail";
import Agents from "./pages/Agents";
import AgentsDetail from "./pages/AgentsDetail";
import RenderRapportoIntervento from "./pages/Clocking/PdfViewer";

function App() {
  const dispatch = useAppDispatch();
  const {data} = useGetSettingsQuery();
  
  
  useEffect(() => {
    if (!data) return;
    
    dispatch(setSettings(data))
  }, [data, dispatch])
  
  return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <ModalsProvider>
          <Layout>
            <Switch>
              <Route path="/">
                <Dashboard />
              </Route>
              <Route path="/customers">
                <Customers />
              </Route>
              <Route path="/customers/create">
                <CustomersCreate />
              </Route>
              <Route path="/customers/:customerId">
                <CustomersDetail />
              </Route>
              <Route path="/agents"> 
                <Agents />
              </Route>
              <Route path="/Agents/:AgentId">
                <AgentsDetail />
              </Route>
              <Route path="/sopralluoghi">
                <Inspections />
              </Route>
              <Route path="/sopralluoghi/create">
                <InspectionCreate />
              </Route>
              <Route path="/sopralluoghi/:id">
                <InspectionDetail />
              </Route>
              <Route path="/proposte">
                <ProposteTecniche />
              </Route>
              <Route path="/clockings">
                <ClockingList />
              </Route>
              <Route path="/clockings/create">
                <ClockingCreate />
              </Route>
              <Route path="/clockings/print">
                <RenderRapportoIntervento />
              </Route>
              <Route path="/clockings/:id">
                <ClockingDetail />
              </Route>
              <Route path="/firstcontact">
                <FirstContact />
              </Route>
              <Route path="/firstcontact/create">
                <FirstContactCreate />
              </Route>
              <Route path="/firstcontact/:id">
                <FirstContactDetail />
              </Route>
              <Route path="/warehouse">
                <Warehouse />
              </Route>
              <Route path="/warehouse/upload">
                <Upload />
              </Route>
              <Route path="/warehouse/item/:id">
                <WarehouseItemDetail />
              </Route>
              <Route path="/warehouse/download">
                <Download />
              </Route>
              <Route path="/proposte/create">
                <ProposteTecnicheCreate />
              </Route>
              <Route path="/proposte/:id">
                <ProposteTecnicheDetail />
              </Route>
              <Route path="/vehicles">
                <Vehicles />
              </Route>
              <Route path="/vehicles/add">
                <VehicleCreate />
              </Route>
              <Route path="/vehicles/:id">
                {(params: any) => <VehicleDetail id={params.id} />}
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/test">
                <InspectionMovements />
              </Route>
            </Switch>
          </Layout>
        </ModalsProvider>
      </MantineProvider>
  );
}

export default App;
