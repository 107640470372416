import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import insectorLogo from "../../images/logo.jpg";
import { IClocking } from "../../models/clocking";
import keycloak from "../../Keycloak";


const styles = StyleSheet.create({
    page: { padding: 30, paddingTop: 15, fontSize: 12, fontFamily: 'Helvetica', borderBottom: '1px solid lightgray' },
    section: { marginBottom: 10, },
    doubleColumnSection: { marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
    signatureSection: { marginTop: 10, display: 'flex', alignItems: 'flex-end' },
    photoSection: { marginTop: 5, marginBottom: 5, display: 'flex', flexDirection: 'row' },
    header: { flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid lightgray' },
    title: { fontSize: 16, fontWeight: 'bold', marginTop: 15, marginBottom: 5, textAlign: 'center' },
    text: { marginBottom: 10 },
    footer: { marginTop: 20, fontSize: 10, textAlign: 'center' },
    bold: { fontWeight: 'bold' },
    uppercase: { textTransform: 'uppercase' },
    subheader: { fontSize: 10, marginTop: 10, textAlign: 'right', width: "80%" },
    logo: { width: 150 },
    signature: { width: 150 },
    filigrana: { position: 'relative' },
    filigranaText: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.5,
        fontSize: 7,
    }
});

const fetchImage = async (fileId) => {
    try {
        const response = await fetch(`/api/document/${fileId}/file`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        });
        if (!response.ok) {
            throw new Error('Image download failed');
        }
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error("Error fetching image:", error);
        return null;
    }
};

interface ClockingPdfProps {
    data: IClocking
}

const ClockingPdf = ({ data }: ClockingPdfProps) => {
    const [photoUrls, setPhotoUrls] = useState<string[]>([]);

    useEffect(() => {
        const loadImages = async () => {
            const urls = await Promise.all(
                data.photos.map(async (photo) => await fetchImage(photo.id))
            );
            setPhotoUrls(urls.filter(url => url !== null));
        };
        loadImages();
    }, [data.photos]);

    return (<Document>
        <Page size="A4" style={styles.page}>
            <View style={{
                borderBottom: '1px solid lightgray',
            }}>
                <View style={styles.header}>
                    <Image
                        style={styles.logo}
                        src={insectorLogo}
                    />
                    <View style={styles.subheader}>
                        <Text>Servizio erogato da GOLD SERVICE S.R.L. (divisione Insector)</Text>
                        <Text>Via Cremasca 24/A - 24052 Azzano San Paolo (BG) - P.IVA 04276620160</Text>
                    </View>
                </View>
                <View>
                    <Text style={styles.title}>RAPPORTO INTERVENTO</Text>
                </View>
                <View style={styles.doubleColumnSection}>
                    <View style={{
                        width: '60%'
                    }}>
                        <Text style={styles.text}><Text style={styles.bold}>Identificativo:</Text> {data.id.slice(0, 15)}</Text>
                        <Text style={styles.text}><Text style={styles.bold}>Tipo intervento:</Text> {data.service.length > 0 ? data.service : data.serviceDescription}</Text>
                        <Text style={styles.text}><Text style={styles.bold}>Svolto presso: </Text>{data.customer.description}</Text>
                        <Text style={styles.text}><Text style={styles.bold}>Indirizzo: </Text>{data.customer.addressForMaps != null && data.customer.addressForMaps != "" ? data.customer.addressForMaps : data.customer.address}</Text>
                        <Text style={styles.text}><Text
                            style={styles.bold}>Data:</Text> {new Date(data.dateStart?.toString()).toLocaleDateString()}
                        </Text>
                        <Text style={styles.text}><Text style={styles.bold}>Ora
                            completamento attività:</Text> {new Date(data.dateEnd?.toString()).toLocaleTimeString()}</Text>
                        <Text style={styles.text}><Text
                            style={styles.bold}>Posizione:</Text> {data.customer.latitude}, {data.customer.longitude}
                        </Text>
                        <Text style={styles.text}><Text style={styles.bold}>Operatore:</Text> {data.userName.split(' ').reverse()[0]} {data.userName.split(' ')[1][0]}. </Text>
                    </View>
                    <View style={{
                        borderLeft: '1px solid lightgray',
                        margin: 20
                    }} />
                    <View style={{
                        width: '40%'
                    }}>
                        <Image
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.customer.latitude},${data.customer.longitude}&zoom=18&size=300x200&maptype=roadmap
    &markers=color:red%7%7C${data.customer.latitude},${data.customer.longitude}&key=AIzaSyAVW5Ge67vajEevlGBdqnuKhBuzT9T5B-E`} />
                        <View style={styles.signatureSection}>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '100%',
                            }}>
                                {data?.referente?.length > 0 &&
                                    <Text style={styles.text}><Text style={styles.bold}>Referente:</Text> <Text style={styles.uppercase}>{data.referente}</Text></Text>}
                                {data.signature.length > 0 && (
                                    <Image
                                        style={{ ...styles.signature, height: 50, objectFit: 'cover', objectPosition: 'top', borderBottom: '1px solid lightgrey' }}
                                        src={data.signature}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{
                marginTop: 10
            }}>
                {photoUrls.length > 0 && (<>
                    <View>
                        <Text style={styles.text}><Text style={styles.bold}>Fotografie:</Text></Text>
                    </View>
                    <View style={styles.photoSection}>
                        {photoUrls.map((photo) => <View style={styles.filigrana}>
                            <Image style={{ width: 250, marginRight: 20 }} src={photo} />
                            <Text style={styles.filigranaText}>{data.customer.latitude}, {data.customer.longitude} {new Date(data.dateStart?.toString()).toLocaleDateString()} {new Date(data.dateEnd?.toString()).toLocaleTimeString()}</Text>
                        </View>)}
                    </View>
                </>
                )}
            </View>
        </Page>
    </Document>
    );
}
export default ClockingPdf;
