import React, { useState, useEffect } from "react";
import { useRoute, useLocation } from "wouter";
import {
    useGetAgentQuery,
    useDeleteAgentMutation,
    useUpdateAgentMutation,
} from "../redux/apis/agentApi";
import {
    Stack,
    Button,
    Group,
    Text,
    Divider,
    Breadcrumbs,
    Anchor,
    TextInput,
    Textarea,
    LoadingOverlay,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IAgent } from "../models/agent";

function AgentsDetail() {
    const [match, params] = useRoute<{ agentId?: string }>("/agents/:agentId");
    const [location, setLocation] = useLocation();

    // Initialize hooks outside of conditions
    const { data: agent, isLoading, error } = useGetAgentQuery(params?.agentId || "");
    const [deleteAgent, { isLoading: isDeleting }] = useDeleteAgentMutation();
    const [updateAgent, { isLoading: isUpdating }] = useUpdateAgentMutation();

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState<IAgent | null>(null);

    useEffect(() => {
        if (agent) {
            setFormData(agent);
        }
    }, [agent]);

    if (!match || !params?.agentId) {
        return <Text color="red">Invalid URL: agent ID is missing.</Text>;
    }

    const handleChange = (field: keyof IAgent, value: string | null) => {
        setFormData((prev) => (prev ? { ...prev, [field]: value } : null));
    };

    const handleSave = async () => {
        if (!formData) return;

        try {
            await updateAgent(formData).unwrap();
            showNotification({
                title: "Success",
                message: "Agent updated successfully!",
                color: "green",
            });
            setIsEditing(false);
        } catch (error) {
            showNotification({
                title: "Error",
                message: "Error updating the agent.",
                color: "red",
            });
        }
    };

    const handleDelete = async () => {
        openConfirmModal({
            title: "Confirm Deletion",
            children: (
                <Text>Are you sure you want to delete this agent? This action is irreversible.</Text>
            ),
            labels: { confirm: "Delete", cancel: "Cancel" },
            confirmProps: { color: "red" },
            onConfirm: async () => {
                try {
                    await deleteAgent(params.agentId!).unwrap();
                    showNotification({
                        title: "Success",
                        message: "Agent deleted successfully!",
                        color: "green",
                    });
                    setLocation("/agents");
                } catch (error) {
                    showNotification({
                        title: "Error",
                        message: "Error deleting the agent.",
                        color: "red",
                    });
                }
            },
        });
    };

    if (isLoading || isDeleting || isUpdating) {
        return <LoadingOverlay visible overlayBlur={2} />;
    }

    if (error) {
        return <Text color="red">Error loading agent data.</Text>;
    }

    if (!formData) {
        return <Text>Loading agent data...</Text>;
    }

    return (
        <div>
            <Breadcrumbs>
                <Anchor href="/agents">Agents</Anchor>
                <Text>{formData.description || "Details"}</Text>
            </Breadcrumbs>

            <form>
                <Stack spacing="xs">
                    <Group position="apart" align="center" mb="md">
                        <h2>Informazioni generali</h2>
                        <Group>
                            {!isEditing ? (
                                <Button onClick={() => setIsEditing(true)} color="blue">Edit</Button>
                            ) : (
                                <Button onClick={handleSave} color="green">Save</Button>
                            )}
                            <Button onClick={handleDelete} color="red" loading={isDeleting}>Delete</Button>
                        </Group>
                    </Group>
                    <Group>
                        <TextInput label="Cod. ID" value={formData.erpCode || ""} onChange={(e) => handleChange("erpCode", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Descrizione" value={formData.description || ""} onChange={(e) => handleChange("description", e.target.value)} disabled={!isEditing} />
                    </Group>
                    <Group>
                        <TextInput label="Indirizzo" value={formData.address || ""} onChange={(e) => handleChange("address", e.target.value)} disabled={!isEditing} />
                        <TextInput label="CAP" value={formData.zipCode || ""} onChange={(e) => handleChange("zipCode", e.target.value)} disabled={!isEditing} />
                    </Group>
                    <Group>
                        <TextInput label="Provincia" value={formData.province || ""} onChange={(e) => handleChange("province", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Nazione" value={formData.country || ""} onChange={(e) => handleChange("country", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Zona" value={formData.zone || ""} onChange={(e) => handleChange("zone", e.target.value)} disabled={!isEditing} />
                    </Group>
                </Stack>

                <Divider my="lg" />

                <Stack spacing="xs">
                    <h2>Dati Fiscali</h2>
                    <Group>
                        <TextInput label="Codice Fiscale" value={formData.cf || ""} onChange={(e) => handleChange("cf", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Partita IVA" value={formData.vat || ""} onChange={(e) => handleChange("vat", e.target.value)} disabled={!isEditing} />
                    </Group>
                </Stack>

                <Divider my="lg" />

                <Stack spacing="xs">
                    <h2>Contatti</h2>
                    <Group>
                        <TextInput label="Telefono" value={formData.telephone || ""} onChange={(e) => handleChange("telephone", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Fax" value={formData.fax || ""} onChange={(e) => handleChange("fax", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Mobile" value={formData.cell || ""} onChange={(e) => handleChange("cell", e.target.value)} disabled={!isEditing} />
                        <TextInput label="Email" value={formData.mail || ""} onChange={(e) => handleChange("mail", e.target.value)} disabled={!isEditing} />
                    </Group>
                </Stack>

                <Divider my="lg" />

                <Textarea label="Note" value={formData.note || ""} onChange={(e) => handleChange("note", e.target.value)} disabled={!isEditing} />
            </form>
        </div>
    );
}

export default AgentsDetail;
