import React, { useState, useEffect } from "react";
import { useRoute, useLocation } from "wouter";
import {
    useGetCustomerQuery,
    useDeleteCustomerMutation,
    useUpdateCustomerMutation,
} from "../redux/apis/customerApi";
import {
    Stack,
    Button,
    Group,
    Text,
    Divider,
    Breadcrumbs,
    Anchor,
    TextInput,
    Textarea,
    LoadingOverlay,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { ICustomer } from "../models/customer";
import GoogleMapComponent from "./GoogleMap";

function CustomersDetail() {
    // @ts-ignore
    const [, { customerId }] = useRoute("/customers/:customerId");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setLocation] = useLocation();

    const { data: customer, isLoading, error } = useGetCustomerQuery(customerId);
    const [deleteCustomer, { isLoading: isDeleting }] = useDeleteCustomerMutation();
    const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation();

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState<ICustomer | null>(null);

    useEffect(() => {
        if (customer) {
            setFormData(customer);
        }
    }, [customer]);

    const handleChange = (field: keyof ICustomer, value: string | null) => {
        setFormData((prev) => (prev ? { ...prev, [field]: value } : null));
    };

    const handleSave = async () => {
        if (!formData) return;

        try {
            setIsEditing(false);
            await updateCustomer(formData).unwrap(); // Invio l'intero oggetto, incluso l'ID
            showNotification({
                title: "Successo",
                message: "Cliente aggiornato con successo!",
                color: "green",
            });
        } catch (error) {
            console.error("Errore durante l'aggiornamento:", error);
            showNotification({
                title: "Errore",
                message: "Errore durante l'aggiornamento del cliente.",
                color: "red",
            });
        }
    };


    const handleDelete = async () => {
        console.log("handleDelete chiamato"); // Log per debug

        openConfirmModal({
            title: "Conferma Eliminazione",
            children: (
                <Text>
                    Sei sicuro di voler eliminare questo cliente? Questa azione è
                    irreversibile.
                </Text>
            ),
            labels: { confirm: "Elimina", cancel: "Annulla" },
            confirmProps: { color: "red" },
            onConfirm: async () => {
                console.log("Conferma eliminazione cliccata, customerId:", customerId); // Log per debug
                try {
                    const result = await deleteCustomer(customerId).unwrap(); // Effettua la cancellazione
                    console.log("Risultato API:", result); // Log per debug
                    showNotification({
                        title: "Successo",
                        message: "Cliente eliminato con successo!",
                        color: "green",
                    });
                    setLocation("/customers"); // Redirige alla lista clienti
                } catch (error) {
                    console.error("Errore API:", error);
                    showNotification({
                        title: "Errore",
                        message: error?.data?.message || "Errore durante l'eliminazione del cliente.",
                        color: "red",
                    });
                }
            },
        });
    };

    if (isLoading || isDeleting || isUpdating) {
        return <LoadingOverlay visible overlayBlur={2} />;
    }

    if (error) {
        return <Text color="red">Errore durante il caricamento del cliente.</Text>;
    }

    if (!formData) {
        return <Text>Caricamento dei dati...</Text>;
    }

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: "10px" }}>
                <Anchor href="/customers">Clienti</Anchor>
                <Text>{formData.description || "Dettagli"}</Text>
            </Breadcrumbs>

            <form
                style={{
                    maxWidth: "100%",
                    margin: "0 auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                {/* Informazioni Generali */}
                <Stack spacing="xs">
                    <Group position="apart" align="center" mb="md">
                        <h2>Informazioni Generali</h2>
                        <Group>
                            {!isEditing ? (
                                <Button onClick={() => setIsEditing(true)} color="blue">
                                    Modifica
                                </Button>
                            ) : (
                                <Button onClick={handleSave} color="green">
                                    Salva
                                </Button>
                            )}
                            <Button onClick={handleDelete} color="red" loading={isDeleting}>
                                Elimina
                            </Button>
                        </Group>
                    </Group>
                    <Group>
                        <TextInput
                            label="Cod. Cliente"
                            value={formData.erpCode || ""}
                            onChange={(e) => handleChange("erpCode", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                        <TextInput
                            label="Cliente"
                            value={formData.description || ""}
                            onChange={(e) => handleChange("description", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "600px" }}
                        />
                    </Group>
                    <Group>
                        <TextInput
                            label="Indirizzo"
                            value={formData.address || ""}
                            onChange={(e) => handleChange("address", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "600px" }}
                        />
                        <TextInput
                            label="CAP"
                            value={formData.zipCode || ""}
                            onChange={(e) => handleChange("zipCode", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                    </Group>
                    <Group>
                        <TextInput
                            label="Provincia"
                            value={formData.province || ""}
                            onChange={(e) => handleChange("province", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "70px" }}
                        />
                        <TextInput
                            label="Città"
                            value={formData.city || ""}
                            onChange={(e) => handleChange("city", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "350px" }}
                        />
                        <TextInput
                            label="Zona"
                            value={formData.zone || ""}
                            onChange={(e) => handleChange("zone", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                        <TextInput
                            label="Nazione"
                            value={formData.country || ""}
                            onChange={(e) => handleChange("country", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "150px" }}
                        />
                    </Group>
                    <Group>
                        <TextInput
                            label="Latitudine"
                            value={formData.latitude || ""}
                            onChange={(e) => handleChange("latitude", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "120px" }}
                        />
                        <TextInput
                            label="Longitudine"
                            value={formData.longitude || ""}
                            onChange={(e) => handleChange("longitude", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "120px" }}
                        />
                        <TextInput
                            label="Indirizzo operativo"
                            value={formData.addressForMaps || ""}
                            onChange={(e) => handleChange("addressForMaps", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "450px" }}
                        />
                    </Group>
                    <Group>
                        <div style={{
                            pointerEvents: !isEditing ? 'none' : 'auto', 
                            height: '400px', 
                            width: '100%',
                            position: 'relative'
                        }}>
                            {!isEditing && <div style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                background: '#80808075',
                                zIndex: 1
                            }}/>}
                            <GoogleMapComponent 
                                startingLat={formData?.latitude}
                                startingLot={formData?.longitude}
                                setLatitude={(val) => handleChange("latitude", val.toString())}
                                setLongitude={(val) => handleChange("longitude", val.toString())}/>
                        </div>
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Dati Fiscali */}
                <Stack spacing="xs">
                    <h2>Dati Fiscali</h2>
                    <Group>
                        <TextInput
                            label="Codice Fiscale"
                            value={formData.cf || ""}
                            onChange={(e) => handleChange("cf", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "200px" }}
                        />
                        <TextInput
                            label="Partita IVA"
                            value={formData.vat || ""}
                            onChange={(e) => handleChange("vat", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "200px" }}
                        />
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Contatti */}
                <Stack spacing="xs">
                    <h2>Contatti del Cliente</h2>
                    <Group>
                        <TextInput
                            label="Telefono"
                            value={formData.telephone || ""}
                            onChange={(e) => handleChange("telephone", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "150px" }}
                        />
                        <TextInput
                            label="Fax"
                            value={formData.fax || ""}
                            onChange={(e) => handleChange("fax", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "150px" }}
                        />
                        <TextInput
                            label="Cellulare"
                            value={formData.cell || ""}
                            onChange={(e) => handleChange("cell", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "150px" }}
                        />
                        <TextInput
                            label="Email"
                            value={formData.mail || ""}
                            onChange={(e) => handleChange("mail", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "300px" }}
                        />
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Altro */}
                <Stack spacing="xs">
                    <h2>Altro</h2>
                    <Group>
                        <TextInput
                            label="Cod. Agente"
                            value={formData.idAgent || ""}
                            onChange={(e) => handleChange("idAgent", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                        <TextInput
                            label="Cod. Referente"
                            value={formData.idReferent || ""}
                            onChange={(e) => handleChange("idReferent", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                        <TextInput
                            label="ID Programm."
                            value={formData.idProgrammer || ""}
                            onChange={(e) => handleChange("idProgrammer", e.target.value)}
                            disabled={!isEditing}
                            sx={{ width: "100px" }}
                        />
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Note */}
                <Textarea
                    label="Note"
                    value={formData.note || ""}
                    onChange={(e) => handleChange("note", e.target.value)}
                    disabled={!isEditing}
                    mt="md"
                    sx={{ width: "100%" }}
                />
            </form>
        </div>
    );
}

export default CustomersDetail;
