import React, {useState, useEffect, useCallback, CSSProperties, useRef} from 'react';
import {GoogleMap, LoadScript, Marker, StandaloneSearchBox} from '@react-google-maps/api';
import {useDebouncedValue} from "@mantine/hooks";

const GoogleMapComponent = ({startingLat, startingLot, setLatitude, setLongitude}: {
    startingLat?: number,
    startingLot?: number,
    setLatitude: (value: number) => void,
    setLongitude: (value: number) => void,
}) => {
    const [center, setCenter] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [address, setAddress] = useState<string>("");
    const [debounceAddress] = useDebouncedValue(address, 500);
    const searchBoxRef = useRef(null);
    
    const geocodeAddress = (address) => {
        if (!window.google) return;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({address}, (results, status) => {
            if (status === 'OK') {
                const {lat, lng} = results[0].geometry.location;
                setCenter({lat: lat(), lng: lng()});
                setMarkerPosition({lat: lat(), lng: lng()});
                setLatitude(lat());
                setLongitude(lng());
            }
        });
    };

    useEffect(() => {
        if (!isLoaded) return;

        if (debounceAddress) {
            geocodeAddress(debounceAddress);
        }
    }, [debounceAddress, isLoaded]);
    


    useEffect(() => {
        if (!isLoaded) return;

        if (startingLot && startingLat) {
            const {lat, lng} = new google.maps.LatLng(startingLat, startingLot);
            
            setCenter({lat: lat(), lng: lng()});
            setMarkerPosition({lat: lat(), lng: lng()});
        }
    }, [startingLot, startingLat, isLoaded])

    const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
        const {latLng} = e;
        const {lat, lng} = latLng;
        setMarkerPosition({lat: lat(), lng: lng()});
        setLatitude(lat());
        setLongitude(lng());
    }, [setLatitude, setLongitude]);

    const onMapLoad = (map) => {
        setIsLoaded(true);
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyAVW5Ge67vajEevlGBdqnuKhBuzT9T5B-E" libraries={['places']}>
            <GoogleMap
                mapContainerStyle={{height: "400px", width: "100%"}}
                center={center}
                zoom={15}
                onClick={onMapClick}
                onLoad={onMapLoad}
            >
                <StandaloneSearchBox onLoad={(ref) => { searchBoxRef.current = ref;  }} 
                                     onPlacesChanged={() => {
                                         const places = searchBoxRef.current.getPlaces();
                                         if (places.length > 0) {
                                             const place = places[0];
                                             setAddress(place.formatted_address);
                                         }
                                     }}>
                    <input
                        type='text'
                        placeholder='Cerca indirizzo..'
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                    />
                </StandaloneSearchBox>
                {markerPosition && <Marker position={markerPosition}/>}
            </GoogleMap>
        </LoadScript>
    );
};

export default GoogleMapComponent;
