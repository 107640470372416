import { createApi } from "@reduxjs/toolkit/query/react";
import { ICustomer } from "../../models/customer";
import { IListResponse } from "../../models/api";
import { baseQueryWithReauth } from "./authApi";

export interface IGetCustomerRequest {
  search: string;
  orderBy: string;
  descending: boolean;
  limit: number;
  skip: number;
  columnsFilter: string[];
  columnsFiltersValues: string[];
}

export interface IGetCustomerNearbyRequest {
  lat: string;
  lon: string;
  distanceInMeters: number;
}

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCustomers: builder.query<IListResponse<ICustomer>, IGetCustomerRequest>({
      query: (args) => {
        const columnFilters = args.columnsFilter.map(
            (x) => `&columnsToFilter=${x}`
        );
        const columnFiltersValues = args.columnsFiltersValues.map(
            (x) => `&columnsFilterValues=${x}`
        );

        return {
          url: `/api/customers?search=${args.search}&orderBy=${
              args.orderBy
          }&orderDesc=${args.descending}&limit=${args.limit}&skip=${
              args.skip
          }${columnFilters.join("&")}${columnFiltersValues.join("&")}`,
        };
      },
    }),
    getCustomersNearby: builder.query<ICustomer[], IGetCustomerNearbyRequest>({
      query: (args) => {
        return {
          url: `/api/customers/nearby?latitude=${args.lat}&longitude=${args.lon}&distanceInMeters=${args.distanceInMeters}`,
        };
      },
    }),
    // Creare un nuovo cliente
    createCustomer: builder.mutation<ICustomer, Partial<ICustomer>>({
      query: (newCustomer) => ({
        url: "/api/customers",
        method: "POST",
        body: newCustomer,
      }),
    }),
    // Modificare un cliente esistente
    updateCustomer: builder.mutation<ICustomer, Partial<ICustomer>>({
      query: (updates) => ({
        url: `/api/customers`,
        method: "PUT",
        body: updates, // L'ID è incluso nel payload, non nell'URL
      }),
    }),
    // Cancellare un cliente
    deleteCustomer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/customers/${id}`,
        method: "DELETE",
      }),
    }),


    // Ottenere un singolo cliente tramite ID
    getCustomer: builder.query<ICustomer, string>({
      query: (id) => ({
        url: `/api/customers/${id}`,
      }),
    }),
  }),
});

// Export hooks per l'utilizzo nei componenti
export const {
  useGetCustomersQuery,
  useGetCustomersNearbyQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation, // Hook per modificare un cliente
  useDeleteCustomerMutation, // Hook per cancellare un cliente
  useGetCustomerQuery, // Hook per ottenere un cliente tramite ID
} = customerApi;