import { useDebouncedValue } from "@mantine/hooks";
import { TableFilter } from "../components/table/DataTable";
import { useGetAgentsQuery } from "../redux/apis/agentApi"; 
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  DEFAULT_PAGE_LIMIT,
  setCurrentPage,
  setFilter,
  setOrderColumnRef,
  setOrderDescending,
  setTableFilters,
} from "../redux/slices/agentSlice"; 
import capitalize from "lodash-es/capitalize";

export const useAgent = () => {
  const dispatch = useAppDispatch();
  const {
    pages,
    currentPage,
    filter,
    tableFilters,
    orderDescending,
    orderColumnRef,
  } = useAppSelector((s) => s.agents); 

  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data } = useGetAgentsQuery({
    search: debouncedFilter,
    orderBy: capitalize(orderColumnRef),
    descending: orderDescending,
    limit: DEFAULT_PAGE_LIMIT,
    skip: (currentPage - 1) * DEFAULT_PAGE_LIMIT,
    columnsFilter: tableFilters.map((x) => capitalize(x.columnRef)),
    columnsFiltersValues: tableFilters.map((x) => x.value),
  });

  const setOrderColumn = (orderColumn: string) => {
    const alreadySelected = 
        orderColumn.toLowerCase() === orderColumnRef.toLowerCase();

    dispatch(setOrderDescending(orderDescending !== alreadySelected));
    dispatch(setOrderColumnRef(orderColumn));
  };

  return {
    agents: data?.items || [], 
    pages,
    currentPage,
    tableFilters,
    setTableFilters: (prev: TableFilter) => dispatch(setTableFilters(prev)),
    setCurrentPage: (v: number) => dispatch(setCurrentPage(v)),
    setFilter: (v: string) => dispatch(setFilter(v)),
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  };
};
