import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { DataTable } from "../components/table/DataTable";
import { useLocation } from "wouter";  // Usa useLocation da Wouter per navigare
import { useCustomer } from "../hooks/useCustomer";
import { Box, Button, Title } from "@mantine/core";

const TITLE_HEIGHT = 42;

const Customers = () => {
  const {
    customers,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useCustomer();

  // Ottieni la funzione di navigazione da useLocation
  const [location, setLocation] = useLocation();

  return (
      <FullWidthContainer>
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: TITLE_HEIGHT,
            }}
            mb={8}
        >
          <Title order={1} mb={8} sx={{ height: TITLE_HEIGHT }}>Clienti</Title>
          {/* Usa setLocation per navigare alla pagina di creazione */}
          <Button onClick={() => setLocation(`/customers/create`)}>
            Crea
          </Button>
        </Box>
        <Box sx={{ height: `calc(100% - ${TITLE_HEIGHT}px)` }}>
          <DataTable
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
              onSearch={(searchValue) => setFilter(searchValue)}
              onSort={(columnRef) => {
                setOrderColumn(columnRef);
              }}
              sortingColumn={orderColumnRef}
              sortingDesc={orderDescending}
              header={[
                {
                  accessor: "id",
                  visible: false,
                },
                {
                  accessor: "erpCode",
                  visible: true,
                  label: "Rif. Gestionale",
                },
                {
                  accessor: "description",
                  visible: true,
                  label: "Cliente",
                },
                {
                  accessor: "address",
                  visible: true,
                  label: "Indirizzo",
                },
                {
                  accessor: "city",
                  visible: true,
                  label: "Città",
                },
                {
                  accessor: "zipCode",
                  visible: true,
                  label: "Cap",
                },
                {
                  accessor: "province",
                  visible: true,
                  label: "Provincia",
                },
                {
                  accessor: "country",
                  visible: true,
                  label: "Nazione",
                },
                {
                  accessor: "zone",
                  visible: false,
                  label: "Zona",
                },
                {
                  accessor: "cf",
                  visible: false,
                  label: "Codice fiscale",
                },
                {
                  accessor: "vat",
                  visible: false,
                  label: "P.Iva",
                },
                {
                  accessor: "telephone",
                  visible: false,
                  label: "Telefono",
                },
                {
                  accessor: "fax",
                  visible: false,
                  label: "Fax",
                },
                {
                  accessor: "cell",
                  visible: false,
                  label: "Cellulare",
                },
                {
                  accessor: "mail",
                  visible: false,
                  label: "Email",
                },
                {
                  accessor: "latitude",
                  visible: false,
                  label: "Latitudine",
                },
                {
                  accessor: "longitude",
                  visible: false,
                  label: "Longitudine",
                },
                {
                  accessor: "note",
                  visible: false,
                  label: "Note",
                },
                {
                  accessor: "idReferent",
                  visible: false,
                  label: "Cod. Referente",
                },
                {
                  accessor: "idAgent",
                  visible: true,
                  label: "Agente",
                },
                {
                  accessor: "idProgrammer",
                  visible: true,
                  label: "Progr.",
                },
                {
                  accessor: "addressformaps",
                  visible: false,
                  label: "Indirizzo per Mappe",
                },

              ]}
              data={customers ?? []}
              pages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onClick={(id: string) => setLocation(`/customers/${id}`)} 
          />
        </Box>
      </FullWidthContainer>
  );
};

export default Customers;
