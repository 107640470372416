import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { agentApi } from "../apis/agentApi";
import { TableFilter } from "../../components/table/DataTable";

export const DEFAULT_PAGE_LIMIT = 100;

// Define a type for the slice state
interface IAgentState {
  pages: number;
  currentPage: number;
  filter: string;
  orderColumnRef: string;
  orderDescending: boolean;
  tableFilters: TableFilter[];
}

// Define the initial state using that type
const initialState: IAgentState = {
  pages: 1,
  currentPage: 1,
  filter: "",
  orderColumnRef: "",
  orderDescending: false,
  tableFilters: [],
};

// @ts-ignore
export const agentSlice = createSlice({
  name: "agent",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setOrderColumnRef: (state, action) => {
      state.orderColumnRef = action.payload;
    },
    setOrderDescending: (state, action) => {
      state.orderDescending = action.payload;
    },
    setTableFilters: (state, action: PayloadAction<TableFilter>) => {
      const filters = state.tableFilters.filter(
          (prevFilter) => prevFilter.columnRef !== action.payload.columnRef
      );

      if (!action.payload.value) state.tableFilters = filters;
      else state.tableFilters = [...filters, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
        agentApi.endpoints.getAgents.matchFulfilled,
        (state, action) => {
          state.pages = Math.ceil(action.payload.length / DEFAULT_PAGE_LIMIT);
        }
    );
  },
});

export const {
  setCurrentPage,
  setFilter,
  setTableFilters,
  setOrderDescending,
  setOrderColumnRef,
} = agentSlice.actions;

export default agentSlice.reducer;
