import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { DataTable } from "../components/table/DataTable";
import { useLocation } from "wouter";  // Usa useLocation da Wouter per navigare
import { useAgent } from "../hooks/useAgent";
import { Box, Title } from "@mantine/core";

const TITLE_HEIGHT = 42;

const Agents = () => {
    const {
        agents,
        pages,
        currentPage,
        tableFilters,
        setTableFilters,
        setCurrentPage,
        setFilter,
        setOrderColumn,
        orderColumnRef,
        orderDescending,
    } = useAgent();

    // Ottieni la funzione di navigazione da useLocation
    const [, setLocation] = useLocation();

    return (
        <FullWidthContainer>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: TITLE_HEIGHT,
                }}
                mb={8}
            >
                <Title order={1} mb={8} sx={{ height: TITLE_HEIGHT }}>Agenti</Title>
            </Box>
            <Box sx={{ height: `calc(100% - ${TITLE_HEIGHT}px)` }}>
                <DataTable
                    tableFilters={tableFilters}
                    setTableFilters={setTableFilters}
                    onSearch={(searchValue) => setFilter(searchValue)}
                    onSort={(columnRef) => {
                        setOrderColumn(columnRef);
                    }}
                    sortingColumn={orderColumnRef}
                    sortingDesc={orderDescending}
                    header={[
                        { accessor: "erpCode", visible: true, label: "Erpcode" },
                        { accessor: "description", visible: true, label: "Description" },
                        { accessor: "address", visible: true, label: "Address" },
                        { accessor: "zipCode", visible: true, label: "Zipcode" },
                        { accessor: "province", visible: true, label: "Province" },
                        { accessor: "country", visible: true, label: "Country" },
                        { accessor: "zone", visible: true, label: "Zone" },
                        { accessor: "cf", visible: true, label: "Cf" },
                        { accessor: "vat", visible: true, label: "Vat" },
                        { accessor: "telephone", visible: true, label: "Telephone" },
                        { accessor: "fax", visible: true, label: "Fax" },
                        { accessor: "cell", visible: true, label: "Cell" },
                        { accessor: "mail", visible: true, label: "Mail" },
                        { accessor: "note", visible: true, label: "Note" },
                    ]}
                    data={agents ?? []}
                    pages={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onClick={(id: string) => setLocation(`/agents/${id}`)}
                />
            </Box>
        </FullWidthContainer>
    );
};

export default Agents;
