import {
  Anchor,
  Breadcrumbs,
  Button,
  Divider,
  Textarea,
  TextInput,
  Stack,
  Group,
} from "@mantine/core";
import React from "react";
import { useCreateCustomerMutation } from '../redux/apis/customerApi';  // Assicurati di importare il hook
import { useInputState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {ICustomer} from "../models/customer";




function CustomersCreate() {
    const [createCustomer, { isLoading }] = useCreateCustomerMutation();
    
    // Stati per i vari campi
    const [erpCode, setErpCode] = useInputState("");
    const [description, setDescription] = useInputState("");
    const [address, setAddress] = useInputState("");
    const [zipCode, setZipCode] = useInputState("");
    const [province, setProvince] = useInputState("");
    const [country, setCountry] = useInputState("");
    const [city, setCity] = useInputState("");
    const [zone, setZone] = useInputState("");
    const [cf, setCf] = useInputState("");
    const [vat, setVat] = useInputState("");
    const [telephone, setTelephone] = useInputState("");
    const [fax, setFax] = useInputState("");
    const [cell, setCell] = useInputState("");
    const [mail, setMail] = useInputState("");
    const [latitude, setLatitude] = useInputState("");
    const [longitude, setLongitude] = useInputState("");
    const [note, setNote] = useInputState("");
    const [idReferent, setIdReferent] = useInputState(null);
    const [idAgent, setIdAgent] = useInputState(null);
    const [idProgrammer, setIdProgrammer] = useInputState(null);
    const [addressForMaps, setAddressForMaps] = useInputState("");

    const breadcrumbsItems = [
        { title: "Home", href: "/" },
        { title: "Clienti", href: "/customers" },
        { title: "Crea", href: "/customers/create" },
    ].map((item, index) => (
        <Anchor href={item.href} key={index}>
            {item.title}
        </Anchor>
    ));

    const handleSubmit = async () => {
        try {
            const newCustomer: Omit<ICustomer, 'id'> = {
                erpCode,
                description,
                address,
                zipCode,
                province,
                country,
                city,
                zone: zone || undefined,
                cf: cf || undefined,
                vat: vat || undefined,
                telephone: telephone || undefined,
                fax: fax || undefined,
                cell: cell || undefined,
                mail: mail || undefined,
                latitude: latitude ? parseFloat(latitude) : undefined,
                longitude: longitude ? parseFloat(longitude) : undefined,
                note: note || undefined,
                ...(idReferent && { idReferent }),
                ...(idAgent && { idAgent }),
                ...(idProgrammer && { idProgrammer }),
                addressForMaps: addressForMaps || undefined,            };

            const response = await createCustomer(newCustomer).unwrap();
            showNotification({
                title: "Successo",
                message: "Cliente creato con successo!",
                color: "green",
            });
        } catch (error) {
            console.error("Errore nella creazione del cliente:", error);
            showNotification({
                title: "Errore",
                message: "Errore nella creazione del cliente",
                color: "red",
            });
        }
    };

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: "10px" }}>{breadcrumbsItems}</Breadcrumbs>

            <form
                onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}
                style={{
                    maxWidth: "100%",
                    margin: "0 auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
                }}
            >
                {/* Primo Gruppo: Informazioni generali */}
                <Stack spacing="xs">
                    <Group position="apart" align="center" mb="md">
                        <h2>Informazioni Generali</h2>
                    </Group>
                    <Group>
                        <TextInput label="Cod. Cliente" value={erpCode} onChange={setErpCode} sx={{ width: "100px" }}/>
                        <TextInput label="Cliente" value={description} onChange={setDescription} sx={{ width: "600px" }}/>
                    </Group>
                    <Group>
                        <TextInput label="Indirizzo" value={address} onChange={setAddress} sx={{ width: "600px" }}/>
                        <TextInput label="CAP" value={zipCode} onChange={setZipCode} sx={{ width: "100px" }} max={99999}/>
                    </Group>
                    <Group>
                        <TextInput label="Provincia" value={province} onChange={setProvince} sx={{ width: "70px" }}/>
                        <TextInput label="Città" value={city} onChange={setCity} sx={{ width: "350px" }}/>
                        <TextInput label="Zona" value={zone} onChange={setZone} sx={{ width: "100px" }}/>
                        <TextInput label="Nazione" value={country} onChange={setCountry} sx={{ width: "150px" }}/>
                    </Group>
                    <Group>
                        <TextInput label="Latitudine" value={latitude} onChange={setLatitude} sx={{ width: "120px" }}/>
                        <TextInput label="Longitudine" value={longitude} onChange={setLongitude} sx={{ width: "120px" }}/>
                        <TextInput label="Indirizzo alternativo per Mappe" value={addressForMaps} onChange={setAddressForMaps} sx={{ width: "450px" }}/>
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Secondo Gruppo: Dati fiscali */}
                <Stack spacing="xs">
                    <h2>Dati Fiscali</h2>
                    <Group>
                        <TextInput label="Codice Fiscale" value={cf} onChange={setCf} sx={{ width: "200px" }}/>
                        <TextInput label="Partita IVA" value={vat} onChange={setVat} sx={{ width: "200px" }}/>
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Terzo Gruppo: Contatti */}
                <Stack spacing="xs">
                    <h2>Contatti del Cliente</h2>
                    <Group>
                        <TextInput label="Telefono" value={telephone} onChange={setTelephone} sx={{ width: "150px" }}/>
                        <TextInput label="Fax" value={fax} onChange={setFax} sx={{ width: "150px" }}/>
                        <TextInput label="Cellulare" value={cell} onChange={setCell} sx={{ width: "150px" }}/>
                        <TextInput label="Email" value={mail} onChange={setMail} sx={{ width: "300px" }}/>
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Quarto Gruppo: Altro */}
                <Stack spacing="xs">
                    <h2>Altro</h2>
                    <Group>
                        <TextInput label="Cod. Agente" value={idAgent} onChange={setIdAgent} sx={{ width: "100px" }}/>
                        <TextInput label="Cod. Referente" value={idReferent} onChange={setIdReferent} sx={{ width: "100px" }}/>
                        <TextInput label="ID Programm." value={idProgrammer} onChange={setIdProgrammer} sx={{ width: "100px" }}/>
                    </Group>
                </Stack>

                <Divider my="lg" />

                {/* Campo Note */}
                <Textarea label="Note" value={note} onChange={setNote} mt="md" sx={{ width: "100%" }}/>

                {/* Bottone di submit */}
                <Button type="submit" loading={isLoading} sx={{ width: "100px" }} mt="lg">
                   Salva
                </Button>
            </form>
        </div>
    );
}

export default CustomersCreate;